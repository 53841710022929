// comment to trigger build
/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { Layout } from '@leshen/gatsby-theme-contentful'
import styled from '@emotion/styled'

import {
  Hero,
  Column,
  Columns,
  VariableContent,
  Image,
  SVGImage,
  List,
  ListItem,
  LeshenPhoneCTA,
  ModalWithState,
  Brandy,
  SplitContent,
  PackageCard,
} from '@leshen/gatsby-theme-leshen'

import { Dropdown, Typography } from '@leshen/ui'
import { graphql, navigate } from 'gatsby'

import getFullStateName from '../utils/getFullStateName'

const StateTemplate = ({ data }) => {
  const { Meta_Description, State, Promo_Code, Page_Path, Meta_Title } =
    data.pageInfo.edges[0].node

  const State_Name = getFullStateName(State)

  const cityDropdownData = data.cities.edges
    .map((current) => ({
      label: current.node.City,
      value: current.node.Page_Path,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))

  const passedData = {
    ...data,
    contentfulPage: {
      seo: {
        canonical: '',
        description: Meta_Description,
        robots: 'index,follow',
        title: Meta_Title,
        titleTemplate: '',
      },
      path: Page_Path,
      promoCode: Promo_Code,
      customBreadCrumbs: null,
      header: data?.allContentfulHeader?.edges[0].node,
      footer: data?.allContentfulFooter?.edges[0].node,
    },
  }

  return (
    <Layout
      data={passedData}
      main={
        <>
          <Hero
            alignImageToBottom={false}
            mobileImage={
              <Image
                data={data.heroImageMobile.cloudinary[0].gatsbyImageData}
                isImageCritical
                alt="Couple shopping for internet plans on laptop"
              />
            }
            mainContent={false}
            image={
              <Image
                data={data.heroImage.cloudinary[0].gatsbyImageData}
                isImageCritical
                alt="Couple shopping for internet plans on laptop"
              />
            }
            CustomLink={false}
            backgroundColor={false}
          >
            <Typography variant="h1">
              Looking for AT&T Internet in {State_Name}?
            </Typography>
            <Typography variant="h5">
              Stay connected to everything you love with AT&T Internet
            </Typography>
            <List>
              <ListItem>
                <Typography>Price includes equipment fees</Typography>
              </ListItem>
              <ListItem>
                <Typography>99% proven reliability</Typography>
                <Brandy
                  text={data.disclaimerNetworkReliability.text}
                  symbol={data.disclaimerNetworkReliability.symbol}
                  variant="legal"
                />
              </ListItem>
            </List>
            <Typography variant="p">Get up to speed with AT&T</Typography>
            <LeshenPhoneCTA variant="hero" color="primary">
              Call
            </LeshenPhoneCTA>
          </Hero>
          <VariableContent
            mainContent={
              <>
                <Typography variant="h2">
                  AT&T Internet packages in {State_Name}
                </Typography>
              </>
            }
            alignMainContent="Center"
            alignImageToBottom={false}
            centeredContent={false}
            backgroundColor={null}
            customLink={null}
          >
            <Columns
              className=""
              leftAligned={false}
              cardStyled={false}
              mobileCarousel={false}
              extraTopSpacingForLabel={false}
            >
              <PackageCard
                label={false}
                packageData={data.package1}
                content={<LeshenPhoneCTA color="primary">Call</LeshenPhoneCTA>}
                modal={
                  <ModalWithState
                    modalText="See offer details"
                    heading={data?.modalData?.heading}
                  >
                    <Brandy
                      key={data.package1Modal.reference}
                      text={data.package1Modal.text}
                      variant="legal"
                    />
                  </ModalWithState>
                }
              />

              <PackageCard
                label={false}
                packageData={data.package2}
                content={<LeshenPhoneCTA color="primary">Call</LeshenPhoneCTA>}
                modal={
                  <ModalWithState
                    modalText="See offer details"
                    heading={data?.modalData?.heading}
                  >
                    <Brandy
                      key={data.package2Modal.reference}
                      text={data.package2Modal.text}
                      variant="legal"
                    />
                  </ModalWithState>
                }
              />
              <PackageCard
                label={false}
                packageData={data.package3}
                content={<LeshenPhoneCTA color="primary">Call</LeshenPhoneCTA>}
                modal={
                  <ModalWithState
                    modalText="See offer details"
                    heading={data?.modalData?.heading}
                  >
                    <Brandy
                      key={data.package3Modal.reference}
                      text={data.package3Modal.text}
                      variant="legal"
                    />
                  </ModalWithState>
                }
              />
            </Columns>
          </VariableContent>
          <VariableContent
            backgroundColor="primary"
            mainContent={
              <>
                <Typography variant="h2">
                  Check availability in your area
                </Typography>
                <Typography variant="p">
                  Enter your zip code to see if AT&T Internet is available in
                  your {State_Name} neighborhood. Don&apos;t miss out on the
                  opportunity to enjoy the best internet experience.
                </Typography>
                <Dropdown
                  options={cityDropdownData}
                  onChange={(e) => navigate(e.value)}
                  placeholderText="Select City"
                />
                {cityDropdownData?.map((current) => (
                  <StyledLink href={current.value} key={current.value}>
                    {current.label}
                  </StyledLink>
                ))}
              </>
            }
          />
          <SplitContent
            backgroundColor=""
            alignImageToBottom={false}
            image={
              <Image
                data={data.blazingFastImage.cloudinary[0].gatsbyImageData}
                isImageCritical
                alt="A flower shop owner tends plants while looking at laptop screen"
              />
            }
            mobileImage={
              <Image
                data={data.blazingFastImageMobile.cloudinary[0].gatsbyImageData}
                isImageCritical
                alt="A flower shop owner tends plants while looking at laptop screen"
              />
            }
            mainContent={
              <>
                <Typography variant="h2">
                  Experience blazing-fast internet speeds and unmatched
                  reliability
                </Typography>
                <Typography variant="p">
                  When it comes to staying connected, streaming your favorite
                  shows, gaming without lag, or working from home seamlessly,
                  you need an internet provider that delivers top-notch
                  performance.
                </Typography>
                <Typography variant="p">
                  Look no further than AT&T Internet in {State_Name}. With our
                  cutting-edge technology, extensive coverage, and outstanding
                  customer support, we&apos;re here to redefine your online
                  experience.
                </Typography>
              </>
            }
          />
          <VariableContent
            mainContent={
              <>
                <Typography variant="h2">
                  What AT&T Internet can do with your {State_Name} home
                </Typography>
              </>
            }
            alignMainContent="Center"
            alignImageToBottom={false}
            centeredContent={false}
            backgroundColor=""
            customLink={null}
          >
            <Columns
              className=""
              leftAligned
              cardStyled={false}
              mobileCarousel={false}
              extraTopSpacingForLabel={false}
              layout="Left Icon"
            >
              <Column
                image={
                  <SVGImage
                    svg={data.connectivityIcon1.cloudinary[0].svg.code}
                    presentationWidth={
                      data.connectivityIcon1.cloudinary[0].svg.presentationWidth
                    }
                  />
                }
              >
                <Typography variant="h4">Lightning-fast speeds</Typography>
                <Typography variant="p">
                  Say goodbye to buffering and slow loading times. AT&T Internet
                  offers lightning-fast speeds, ensuring you can effortlessly
                  stream HD content, video conference with crystal-clear
                  quality, and download large files in a matter of seconds.
                </Typography>
              </Column>
              <Column
                borderColor="none"
                borderType="none"
                image={
                  <SVGImage
                    svg={data.connectivityIcon2.cloudinary[0].svg.code}
                    presentationWidth={
                      data.connectivityIcon2.cloudinary[0].svg.presentationWidth
                    }
                  />
                }
              >
                <Typography variant="h4">Reliability you can trust</Typography>
                <Typography variant="p">
                  Our advanced network infrastructure is designed to provide
                  unparalleled reliability. Whether you&apos;re in the heart of
                  a city or a more remote location, you can count on a stable
                  connection for all your online activities.
                </Typography>
              </Column>
              <Column
                borderColor="none"
                borderType="none"
                image={
                  <SVGImage
                    svg={data.connectivityIcon3.cloudinary[0].svg.code}
                    presentationWidth={
                      data.connectivityIcon3.cloudinary[0].svg.presentationWidth
                    }
                  />
                }
              >
                <Typography variant="h4">Endless entertainment</Typography>
                <Typography variant="p">
                  Dive into a world of entertainment with AT&T Internet. Stream
                  your favorite movies and TV shows, conquer online gaming
                  battles, and discover new music – all without any
                  interruptions.
                </Typography>
              </Column>
              <Column
                borderColor="none"
                borderType="none"
                image={
                  <SVGImage
                    svg={data.connectivityIcon4.cloudinary[0].svg.code}
                    presentationWidth={
                      data.connectivityIcon4.cloudinary[0].svg.presentationWidth
                    }
                  />
                }
              >
                <Typography variant="h4">Flexible plans</Typography>
                <Typography variant="p">
                  We understand that every household has unique internet needs.
                  That&apos;s why we offer a range of plans to fit your usage
                  and budget requirements. Whether you&apos;re a casual internet
                  user or a power user, we have the perfect plan for you.
                </Typography>
              </Column>
              <Column
                borderColor="none"
                borderType="none"
                image={
                  <SVGImage
                    svg={data.connectivityIcon5.cloudinary[0].svg.code}
                    presentationWidth={
                      data.connectivityIcon5.cloudinary[0].svg.presentationWidth
                    }
                  />
                }
              >
                <Typography variant="h4">Seamless connection</Typography>
                <Typography variant="p">
                  AT&T Internet doesn&apos;t just connect devices; it connects
                  people. With our seamless connectivity, you can share
                  memories, collaborate on projects, and stay in touch with
                  loved ones near and far.
                </Typography>
              </Column>
              <Column
                borderColor="none"
                borderType="none"
                image={
                  <SVGImage
                    svg={data.connectivityIcon6.cloudinary[0].svg.code}
                    presentationWidth={
                      data.connectivityIcon6.cloudinary[0].svg.presentationWidth
                    }
                  />
                }
              >
                <Typography variant="h4">24/7 customer support</Typography>
                <Typography variant="p">
                  Our dedicated support team is available round the clock to
                  assist you with any queries or concerns you may have.
                  We&apos;re here to ensure that your internet experience
                  remains hassle-free.
                </Typography>
              </Column>
            </Columns>
          </VariableContent>
          <SplitContent
            backgroundColor="light"
            alignImageToBottom={false}
            image={
              <Image
                data={data.supportImage.cloudinary[0].gatsbyImageData}
                isImageCritical
                alt="A customer service rep talks on headset while smiling and looking at computer screen"
              />
            }
            mobileImage={
              <Image
                data={data.supportImageMobile.cloudinary[0].gatsbyImageData}
                isImageCritical
                alt="A customer service rep talks on headset while smiling and looking at computer screen"
              />
            }
            mainContent={
              <>
                <Typography variant="h2">
                  Get guidance and answers from our team
                </Typography>
                <Typography variant="p">
                  When you speak with a sales agent, you&apos;ll get tailored
                  guidance to match your needs. They assist in choosing the best
                  plan, package, and services for optimal value.
                </Typography>
                <Typography variant="p">
                  Additionally, agents swiftly address questions, concerns, and
                  special requests, reducing uncertainties about services,
                  installation, or equipment
                </Typography>
                <LeshenPhoneCTA color="primary">Call</LeshenPhoneCTA>
              </>
            }
          />
        </>
      }
    />
  )
}

StateTemplate.propTypes = {
  data: PropTypes.shape({}).isRequired,
}

export default StateTemplate

const StyledLink = styled.a`
  display: none !important;
`

export const query = graphql`
  query StateTemplateQuery($pagePath: String, $stateAbbreviation: String) {
    site {
      ...LeshenMetadata
    }
    pageInfo: allDatasetManagerAttAtsAoa(
      filter: { Page_Path: { eq: $pagePath } }
    ) {
      edges {
        node {
          State
          City
          Promo_Code
          Page_Path
          Meta_Title
          Meta_Description
          Zip_Code
        }
      }
    }
    cities: allDatasetManagerAttAtsAoa(
      filter: { State: { eq: $stateAbbreviation }, Area_Type: { eq: "city" } }
    ) {
      edges {
        node {
          City
          Page_Path
        }
      }
    }
    heroImage: contentfulMedia(contentful_id: { eq: "sYLKcX8MecGTYsNRqPaLS" }) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    heroImageMobile: contentfulMedia(
      contentful_id: { eq: "48sohcTLJodpBbX94wUVFY" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    package1: brandyPackage(brandy_id: { eq: "att-internet1000" }) {
      brand_token
      brandy_id
      name
      details
      bullets {
        text
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      price {
        currency
        amount
        sale
        prefix
        duration
        suffix
        before
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      comparison
      labelOffer
    }
    package2: brandyPackage(brandy_id: { eq: "att-internet500" }) {
      brand_token
      brandy_id
      name
      details
      bullets {
        text
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      price {
        currency
        amount
        sale
        prefix
        duration
        suffix
        before
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      comparison
      labelOffer
    }
    package3: brandyPackage(brandy_id: { eq: "att-internet300" }) {
      brand_token
      brandy_id
      name
      details
      bullets {
        text
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      price {
        currency
        amount
        sale
        prefix
        duration
        suffix
        before
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      comparison
      labelOffer
    }
    blazingFastImage: contentfulMedia(
      contentful_id: { eq: "4dDjiGp4RoyLWGj9VUxWiu" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    blazingFastImageMobile: contentfulMedia(
      contentful_id: { eq: "5vhLYpRgIK8RQCYcdy0Ldl" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    connectivityIcon1: contentfulMedia(
      contentful_id: { eq: "2Dp6SA3xmDXdC3pTGITlxG" }
    ) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    connectivityIcon2: contentfulMedia(
      contentful_id: { eq: "1m8uujLocg2cy83USWsrIb" }
    ) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    connectivityIcon3: contentfulMedia(
      contentful_id: { eq: "6x57zcE0EZXXf47QkjfDY4" }
    ) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    connectivityIcon4: contentfulMedia(
      contentful_id: { eq: "18rSLwNV0IZs7df4KDb9Su" }
    ) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    connectivityIcon5: contentfulMedia(
      contentful_id: { eq: "1sB5nkjnbmBzIBgZ8Hblxp" }
    ) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    connectivityIcon6: contentfulMedia(
      contentful_id: { eq: "4C6ooWzBOtIbPNviqi5X2G" }
    ) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    paidToSwitch1: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-rewardcardproximity" }
    ) {
      id
      text
      symbol
    }
    paidToSwitch2: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-rewardcardcancelfeeproximity" }
    ) {
      id
      text
      symbol
    }
    disclaimerRewardcardmodal: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-rewardcardmodal" }
    ) {
      id
      text
      symbol
    }
    supportImage: contentfulMedia(
      contentful_id: { eq: "5502Bx4vAqcrfoq8mx4F5n" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    supportImageMobile: contentfulMedia(
      contentful_id: { eq: "3k8tSPOkt0yRsyJEs02Fui" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    package1Modal: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-modal-internet1000" }
    ) {
      id
      text
      symbol
    }
    package2Modal: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-modal-internet500" }
    ) {
      id
      text
      symbol
    }
    package3Modal: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-modal-internet300" }
    ) {
      id
      text
      symbol
    }
    disclaimerNetworkReliability: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-att-basedonnetworkavailability" }
    ) {
      id
      text
      symbol
    }
    disclaimerCopyright: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-att-copyright" }
    ) {
      id
      text
      symbol
    }
    allContentfulHeader(
      filter: { id: { eq: "c8135650-abc9-547b-87a1-c0b4439613dd" } }
    ) {
      edges {
        node {
          id
          __typename
          identifier
          navigationPosition
          hideLocation
          navigationPosition
          headerBackgroundColor
          headerTextColor
          topBarBackgroundColor
          topBarTextColor
          navigationBackgroundColor
          navigationTextColor
          hoverBackgroundColor
          hoverTextColor
          logoLink
          borderColor
          borderHeight
          textBlockAlignment
          textBlock {
            raw
          }
          linkGroups {
            ...LeshenLinkGroup
            ...LeshenNavigationLink
          }
          topBarLinks {
            ...LeshenNavigationLink
          }
          callToActions {
            ... on ContentfulButton {
              ...LeshenButton
            }
            ... on ContentfulPhoneNumber {
              ...LeshenPhoneNumber
            }
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
        }
      }
    }
    allContentfulFooter(
      filter: { id: { eq: "9d033195-2170-50be-91d5-d8da20a0144e" } }
    ) {
      edges {
        node {
          __typename
          id
          identifier
          linkGroups {
            ...LeshenLinkGroup
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
          disclaimers {
            ...LeshenDisclaimer
          }
          navigationBackgroundColor
          navigationTextColor
          disclaimerBackgroundColor
          disclaimerTextColor
          logoLink
          logoBackgroundColor
          logoTextColor
          logoTopBorder
          logoBottomBorder
        }
      }
    }
  }
`
